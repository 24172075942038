/* 

.designer-team {
    min-height: 100vh; 
    padding: 50px 20px; 
    background: linear-gradient(135deg, #f0f0f0, #dcdcdc);
    text-align: center;
  }
  
  .designer-team-title {
    color: #FFD700;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600; 
  }
  
  .designer-team-description {
    color: #555;
    font-size: 1.1rem;
    margin-bottom: 50px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
  }
  
  .designer-team-container {
    display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .designer-card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 220px; 
    padding: 20px;
    text-align: center;
    border: 1px solid #eaeaea; 
  }
  
  .designer-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15); 
  }
  
  .designer-image {
    width: 120px; 
    height: 150px;
    border-radius: 20%;
    object-fit: cover;
    margin-bottom: 15px;
    border: 3px solid #FFD700; 
  }
  
  .designer-name {
    color: #d4af37;
    font-size: 1.3rem;
    margin: 10px 0;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .designer-role {
    color: #444;
    font-size: 0.95rem;
    margin: 5px 0;
    font-style: italic; 
  }
  
  .loading {
    font-size: 1.5rem;
    color: #777;
    text-align: center;
    padding: 50px;
  }
   */


/* Designer Team Section */
.designer-team {
  padding: 50px;
  /* background:radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%); */
  background: linear-gradient(135deg, #0f3d0f, #1c742c); 
  color: #fff;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Arial", sans-serif;
  overflow: hidden;
}

.designer-team-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #ffd700; /* French golden for title */
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
}

.designer-team-title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 80px;
  height: 4px;
  background: #ffd700;
  transform: translateX(-50%);
  border-radius: 10px;
}

.designer-team-description {
  font-size: 1.5rem;
  margin-bottom: 40px;
  color: #e6e6e6;
  max-width: 700px;
}

.designer-team-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

/* Designer Card */
.designer-card {
  background: linear-gradient(145deg, #ffffff, #e9e9e9); /* White with depth */
  border-radius: 20px;
  text-align: center;
  width: 300px;
  padding: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  position: relative;
}

.designer-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  background: linear-gradient(145deg, #eaf4ea, #f5f5f5);
}

.designer-image {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 20px;
  border: 3px solid #ffd700; /* French golden border */
}

.designer-info {
  text-align: center;
}

.designer-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: #1c742c; /* Green for futuristic emphasis */
  margin-bottom: 5px;
}

.designer-role {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 10px;
  font-style:calc()
}

.designer-phoneNumber,
.designer-email {
  font-size: 1.2rem;
  color: #444;
  margin: 5px 0;
}

/* Learn More Button */
.designer-learn-more {
  margin-top: 20px;
  padding: 12px 25px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background: #1c742c;
  border: 2px solid #ffd700;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.designer-learn-more:hover {
  background: #0f3d0f;
  transform: scale(1.1);
}

/* Animation Enhancements */
.designer-card {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .designer-team-container {
    flex-direction: column;
  }

  .designer-card {
    width: 90%;
  }
}

/* @media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
} 
  */



/* 
@media screen and (max-width: 400px) {
  #features,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    padding: 20px;  
    width: 100%;   
  }

  #portfolio {
    width: 100%;   
}

@media (max-width: 767px) {
  .navbar-brand {
    font-size: 18px; 
  }

  .navbar-nav > li > a {
    padding: 10px 15px;t 
  }

  .navbar {
    padding: 10px;
  }
}



@media screen and (max-width: 438px) {
  .navbar-title {
    font-size: 14px; 

  .navbar-brand img {
    height: 40px; 
  }

  .navbar-nav > li > a {
    font-size: 12px; 
    padding: 8px 10px; 
  }

  .navbar {
    padding: 5px 0; }

  .navbar-toggle {
    padding: 6px 10px; 
  }

  .icon-bar {
    background-color: white;
} */






/* Adjustments for small screens */
@media (max-width: 767px) {
  .navbar-brand {
    font-size: 18px; /* Adjust brand font size */
  }

  .navbar-nav > li > a {
    font-size: 14px; /* Adjust link font size */
    padding: 10px; /* Adjust padding for better touch targets */
  }

  .navbar {
    padding: 5px 0; /* Decrease overall navbar padding */
  }

  .navbar-header img {
    height: 40px; /* Decrease logo height on small screens */
  }
}

/* Further adjustments for screens under 438px */
@media (max-width: 525px) {
  .navbar-title {
    font-size: 20px; /* Further adjust brand font size */
  }

  .navbar-nav > li > a {
    font-size: 12px; /* Further adjust link font size */
    padding: 8px 5px; /* Adjust padding for better touch targets */
  }

  .navbar {
    padding: 5px 0; /* Maintain smaller navbar padding */
  }
}


@media screen and (max-width: 469px){
  .navbar-title{
    font-size: 15px;
  }
}

@media screen and (max-width: 399px){
  .navbar-title{
    font-size: 10px;
  }
}
/* ----------------------------------------------------------------------------------------------- */










/* Base styles for Features section */
#features h3 {
  font-size: 2rem; /* Default heading size */
}

#features p {
  font-size: 1.25rem; /* Default paragraph size */
}

#features i {
  font-size: 3rem; /* Default icon size */
}

/* Media queries for responsiveness */
@media (max-width: 576px) {
  #features h3 {
    font-size: 1.25rem; /* Smaller heading on very small screens */
  }

  #features p {
    font-size: 0.9rem; /* Smaller paragraph text */
  }
  
  /* Adjust icon size if needed */
  #features i {
    font-size: 2.5rem; /* Adjust icon size for small screens */
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  #features h3 {
    font-size: 1.5rem; /* Heading size for small devices */
  }

  #features p {
    font-size: 1.5rem; /* Paragraph size for small devices */
  }
}
/* ------------------------------------------------------------------------ */






